import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"

import {Link} from "gatsby"
import Illustration from "../images/notfound.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" description="You'll see this page, because your requested page doesn't exist. Please go back to home." />
    
    <header className="hero">
      <div className="hero-content">
        <div className="hero-text">
          <img src={Illustration} alt="Not found graphic" width="300px"/>
          <h1>Oops, the page wasn't found</h1>

          <p>
            I'm sorry but the requested page doesn't seem to exist. Maybe you
            mistyped something in the url or clicked an old link. Just go back
            to the homepage to get back on track :)
          </p>
          <Link to="/">Go to homepage</Link>
        </div>
      </div>
    </header>
  </Layout>
)

export default NotFoundPage
